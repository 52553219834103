<script setup>

definePageMeta({
  layout: 'plain',
})

const digitalOrder = useDigitalOrder();
const calculator = useCalculator();
const backend = useBackend();
const route = useRoute();

await useAsyncData(async () => {

  let result;

  if (route.params.model && route.params.body) {
    result = await backend.getCars({
      brandName: route.params.brandOrId,
      modelName: route.params.model,
      bodyType: route.params.body
    })
  } else {
    if (route.params.brandOrId) {
      result = await backend.getCar(route.params.brandOrId, false);
    }
  }

  if (!result || !result.data) {
    return navigateTo("/", {redirectCode: 301, replace: true});
  }

  const transactionCar = result.data[0] ? result.data[0] : result.data;

  const groupId = transactionCar.group_id;

  if (!groupId) {
    return navigateTo("/", {redirectCode: 301, replace: true});
  }

  const price = result.data[0] ? result.data[0].price : result.data.price;

  const [{installment}] = await calculator.getInstallment({data: transactionCar});
  await digitalOrder.createDigitalOrder(groupId, transactionCar.brandName, transactionCar.modelName, installment, transactionCar.price)

})

</script>

<template>
  <div><EffectLoader /></div>
</template>
